import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './store'
import { useAppState } from './store/AppState'
import router from './plugins/router'

Vue.config.productionTip = false

Vue.prototype.$api = axios.create({
	baseURL: process.env.VUE_APP_APIBASE,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
})

const someStorePlugin = {
	install (Vue, options) {
		Vue.prototype.store = useAppState(options.pinia)
	}
}

Vue.use(someStorePlugin, { pinia })

new Vue({
	vuetify,
	pinia,
	router,
	data: {
		ukuran: {
			picture_sample: 1000*1000*2,
			picture_population: 1000*1000*2,
		},
		str_ukuran: {
			picture_sample: 'Maksimal 2 MB',
			picture_population: 'Maksimal 2 MB',
		},
		prodMode: process.env.VUE_APP_PRODMODE,
		publicPath: process.env.BASE_URL,
		envWorkingPath: process.env.VUE_APP_HOMEDIR,
		thumbImage: process.env.VUE_APP_THUMBIMAGE + '?t=',
	},
	render: h => h(App)
}).$mount("#app")
